.navbar {
    height: 50px;
    background: rgb(216,183,120);
    /* background: linear-gradient(59deg, rgba(216,183,120,1) 0%, rgba(216,183,120,1) 0%, rgba(100,83,38,1) 0%, rgba(196,172,119,1) 16%, rgba(198,166,103,1) 27%, rgba(242,209,153,1) 50%, rgba(201,173,105,1) 61%, rgba(232,211,170,1) 73%, rgba(216,185,111,1) 86%, rgba(173,141,71,1) 100%); */
    background-image: url('../../assets/images/test.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 10;
}

.navbar-title {
    margin: 0;
    padding: 0;
    padding-left: 10px;
}

.navbar-title a {
    font-size: 60px;
    letter-spacing: 5px;
    font-family: 'Raleway', sans-serif;
    /* line-height: 0.85em; */
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.navbar-contact-icon {
    position: absolute;
    margin-top: 4px;
    left: 50%;
    margin-left: -25px;
    cursor: pointer;
}

.navbar-contact-icon img {
    height: 45px;
}

.search-bar {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.search-border {
    background-color: white;
    padding: 2px;
    border-radius: 50px;
    border: 1px solid black;
    margin-right: 20px;
}

.search-bar form {
    display: flex;
    height: 25px;
    padding: 10px;
    align-items: center;
    background: black;
    min-width: 18%!important;
    border-radius: 50px;
}

.navbar form .search-data{
    width: 100%;
    height: 100%;
    color: white;
    font-size: 15px;
    border: none;
    font-weight: 500;
    background: none;
    margin-top: 0px;
  }

  .search-icon-border {
    background-color: white;
    padding: 2px;
    border-radius: 50px;
    border: 1px solid black;
    margin-right: 20px;
  }

  .search-icon {
    background-color: black;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    height: 15px;
  }


  @media (max-width: 1280px) {
    .search-icon-border {
        display: none;
    }

    .search-bar {
        margin-right: 0px;
    }

    .search-border {
        width: 30vw;
    }

    .navbar-title {
        margin: 0;
        padding: 0;
        padding-left: 10px;
        text-align: center;
    }
    
    .navbar-title a {
        font-size: 3vw;
        font-weight: 600;
        letter-spacing: 5px;
        font-family: 'Raleway', sans-serif;
        /* line-height: 0.85em; */
        cursor: pointer;
        text-decoration: none;
        color: black;
    }

    .navbar-contact-icon {
        /* display: none; */
    }
  }

  /* Contact navbar design */

  .navbar-contact-icon.contact-version {
    position: initial;
    margin-left: 20px;
  }

  .navbar-title.contact-version {
    position: absolute;
    margin-left: 80px;
  }