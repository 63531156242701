* {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.background-image {
    background-image: url('../images/maintenance.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
    height: 100vh; 
}

.title {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 5vw;
    text-shadow: -2px 5px 0px rgba(0,0,0,0.2);
    text-align: center;
}

.social {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2vw;
}

.social a {
    color: rgb(0, 49, 122);
    text-decoration: none;
}

.social a:hover {
    color: rgb(4, 70, 168);
}

.copyright {
    margin-bottom: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}


@media screen and (max-width: 1360px) {
    .title {
        text-shadow: -2px 4px 0px rgba(0,0,0,0.2);
    }

    .social {
        font-size: 2.5vw;
    }
}

@media screen and (max-width: 1000px) {
    .title {
        text-shadow: -0.5px 1px 0px rgba(0,0,0,0.2);
    }

    .social {
        font-size: 3vw;
    }
}


@media screen and (max-width: 640px) {

    .title {
        top: 40%;
        font-size: 8vw;
    }

    .social {
        font-size: 5vw;
    }

    .copyright {
        font-size: 4.5vw;
    }
}