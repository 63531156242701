* {
    box-sizing:  border-box;
    margin:0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

body {
    overflow: hidden;
    height: 100vh;
}

.home {
    display: flex;
    height: 85vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.home {
    /* background-image: url('./images/img-home.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


@media screen and (max-width: 960px) {
    .home {
        background-image: url('./images/test.jpg');
    }
}

.biography {
    margin: auto;
    text-align: justify;
    width: 60%;
    margin-bottom: 10rem;
}

.biography-content {
    margin-top: 50px;
}

.biography-text {
    font-family: 'helvetica-w01-light,helvetica-w02-light,sans-serif';
}

.page-title {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}



.biography img {
    margin-right: 15px;
    margin-bottom: 10px;
    /* border-radius: 9px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    float: left;
    width: 400px;
}

@media screen and (max-width: 1100px) {
    .biography {
        width: 85%;
    }
    
    .biography img {
        float:none;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        margin-bottom: 30px;
        width: 250px;
    }

    .page-title {
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
    }
}

.contact-details {
    margin-top: 30px;
    font-size: 24px;
}

.contact-form {
    margin-top: 30px;
}

.contact-details i {
    margin-top: 10px;
}

.contact-form textarea {
    width: 30rem;
    background-color: #f6f6f6;
    display: block;
    margin: 5px 0 0;
    padding: 15px;
    border: 0;
    outline: 0;
    resize: none;
    font-size: 18px;
    line-height: 17px;
    font-family: Raleway;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 0px !important;
}

.contact-form input[type=text], input[type=password], input[type=email], input[type=tel], input[type=date], input[type=month], input[type=week], input[type=time], input[type=number], input[type=search], input[type=url] {
    height: 50px;
    width: 30rem;
    background-color: #f6f6f6;
    display: block;
    margin: 5px 0 0;
    padding: 15px;
    border: 0;
    outline: 0;
    resize: none;
    font-size: 18px;
    line-height: 17px;
    font-family: Raleway;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 0px !important;
}

.contact-form input[type=submit] {
    background-color: black !important;
    border: 0px !important;
    padding: 12px 25px !important;
    color: #fff !important;
    font-size: 18px !important;
    letter-spacing: 2px !important;
    font-weight: bold !important;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 5px !important;
    font-family: "Raleway",sans-serif !important;
}

@media screen and (max-width: 1100px) {
    .contact-details {
        font-size: 18px;
    }
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.facebook-feed {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 30px;
    margin-bottom: 100px;
}