.contact-page-container {
    background-color: black;
    height: 97vh;
    width: 100%;
    color: white;
    padding: 65px;
    /* overflow: scroll; */
}

.contact-row {
    display: flex;
    justify-content: space-between;
}

.contact-row textarea {
    margin-top: 50px;
}

.contact-page-form {
    margin-top: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    letter-spacing: 3px;
}

.contact-page-form button {
    margin-top: 15px;
    color: white;
    background-color: #272727;
    border: none;
    padding: 9px 35px;
    float: right;
    cursor: pointer;
}

.contact-page-form button:hover {
    background-color: #383838;
}

.contact-page-form input {
    width: 100%;
    border-radius: 50px;
}

.contact-image img {
    height: 350px;
}

/* Responsiveness */

@media (max-width: 1400px) {
    .contact-page-container {
        overflow: scroll;
    }

    .contact-image img {
        display: none;
    }
}

@media (max-width: 1050px) {
    .contact-page-container {
        padding: 10px;
    }
    .contact-row {
        display: initial;
    }
}