.footer-container {
    background-color: black;
    color: white;
    /* color: linear-gradient(59deg, rgba(216,183,120,1) 0%, rgba(216,183,120,1) 0%, rgba(100,83,38,1) 0%, rgba(196,172,119,1) 16%, rgba(198,166,103,1) 27%, rgba(242,209,153,1) 50%, rgba(201,173,105,1) 61%, rgba(232,211,170,1) 73%, rgba(216,185,111,1) 86%, rgba(173,141,71,1) 100%); */
    position: absolute;
    bottom: 0;
    width: 100%;
    position: fixed;
    padding: 10px;
}

.golden-text {
    background-image: linear-gradient(59deg, rgba(216,183,120,1) 0%, rgba(216,183,120,1) 0%, rgba(100,83,38,1) 0%, rgba(196,172,119,1) 16%, rgba(198,166,103,1) 27%, rgba(242,209,153,1) 50%, rgba(201,173,105,1) 61%, rgba(232,211,170,1) 73%, rgba(216,185,111,1) 86%, rgba(173,141,71,1) 100%);
    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.footer-content {
    font-family: 'Raleway', sans-serif;
    letter-spacing: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    word-spacing: 3px;
}

.footer-content i {
    display: flex;
    justify-content: center;
    font-size: 12px;
}

.footer-break {
    display: none;
}

@media (max-width: 640px) {

    .footer-break {
        display: block;
    }
}