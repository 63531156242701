.home-container {
    background-color: black;
    height: 92vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.sub-category-container {
    text-align: center;
}

.home-title {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 24px;
}

.home-subtitle {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
}

.sub-category-container img {
    height: 50vh;    
    cursor: pointer;
}

.sub-category-container:hover {
    transform: translateY(-20px);
    transition: all 0.3s linear;
}

.scroll-icon {
    display: none;
    color: rgb(216,183,120);
}

@media (max-width: 1280px) {
    .home-container {
        display: block;
        height: 90vh;
        padding: 10vh 0px 0px 0px;
        overflow-y: scroll;
        overflow-x: hidden;
        scroll-snap-type: y mandatory;
    }

    .sub-category-container {
        height: 88vh;
    }

    .sub-category-container:hover {
        transform: none;
        transition: none;
    }

    .sub-category-container img {
        height: 50vh;
        scroll-snap-align: center;
    }

    .home-title {
        font-family: 'Raleway', sans-serif;
        font-weight: 600;
        /* font-size: 1.3em; */
    }
    
    .home-subtitle {
        font-family: 'Raleway', sans-serif;
        font-weight: 600;
    }

    .scroll-icon {
        padding: 5px 100px;
        display: block;
        margin: 20px auto;
    }
    
}

@media (max-width: 450px) {
    .sub-category-container img {
        height: 40vh;
    }
}

@media (max-width: 350px) {

}

.home-link {
    text-decoration: none;
}