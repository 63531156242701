.contact-element::first-child {
    margin-bottom: 50px;
}

.contact-element hr{
    max-width: 400px;
    height: 4px;
    background-image: linear-gradient(59deg, rgba(216,183,120,1) 0%, rgba(216,183,120,1) 0%, rgba(100,83,38,1) 0%, rgba(196,172,119,1) 16%, rgba(198,166,103,1) 27%, rgba(242,209,153,1) 50%, rgba(201,173,105,1) 61%, rgba(232,211,170,1) 73%, rgba(216,185,111,1) 86%, rgba(173,141,71,1) 100%);
    margin: 10px 0 20px 0;
    border: 0;
}

.contact-element-title {
    font-family: 'Raleway', sans-serif;
    letter-spacing: 10px;
    font-size: 60px;
}

.contact-data a {
    text-decoration: none;
    color: white;
}

.contact-entry {
    font-size: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.contact-entry:hover .contact-text{
    transform: translateX(10px);
    transition: all 0.2s linear;;
}

.contact-entry:hover span {
    visibility: initial;
}

.contact-icon {
    height: 50px;
}

.contact-icon img {
    height: 40px;
    margin: 5px;
}

.contact-icon.multiple img:not(:first-child) {
    margin-left: -12px;
}

.contact-text {
    font-family: 'Raleway', sans-serif;
    margin-left: 5px;
}

.contact-text span {
    margin-left: 10px;
    visibility: hidden;
}

/* Responsiveness */

@media (max-width: 1050px) {
    .contact-element {
        margin: 20px 0;
    }

    .contact-element-title {
        font-size: 10vw;
    }

    .contact-text {
        font-size: 5vw;
    }
}